import React,{useState,useEffect,lazy} from 'react';
import axios from 'axios'
import {  Slider,Statistic,Tabs,Modal } from 'antd';
import { ArrowUpOutlined,BankFilled,MinusCircleFilled,ToolOutlined, ArrowDownOutlined,FileFilled,WalletFilled,SearchOutlined,FundViewOutlined,
   UserOutlined, AccountBookOutlined,CalculatorFilled,SwapOutlined,GroupOutlined,AccountBookFilled, DollarCircleOutlined,EditFilled,MessageFilled,DollarOutlined,CarFilled,LoadingOutlined,PlusCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import { Line,Pie,Bar } from 'react-chartjs-2';
import {withGetScreen} from 'react-getscreen'
import { Tooltip,Popover,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import {
  Col,
  Row,
} from 'reactstrap';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'
import * as serverconfig from '../serverconn'
import * as serversocketconfig from '../serversocketconfig'
import { MdShoppingCart } from 'react-icons/md';

import CurrencyFormat from 'react-currency-format';
import Highlighter from 'react-highlight-words';
import Websocket from 'react-websocket';
import { Link } from 'react-router-dom';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { NavLink } from 'react-router-dom'
import  MemberForm from '../components/MemberForm'
import  LoanCalculator from '../components/LoanCalculator'
import { connect } from 'react-redux'
import { withRouter} from 'react-router-dom';
import * as actions from '../../store/actions/auth'
import MyContext from './MyContext';


var CryptoJS = require("crypto-js");

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


const style = { background: '#0092ff', padding: '8px 0',borderRadius: '7px',justifyContent:'center', alignItems:'center',display: 'flex', };
const dateFormat = 'DD/MM/YYYY';
const yeardateFormat = 'YYYY';
const monthdateFormat = 'MM/YYYY';

const { Meta } = Card;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const FormItem=Form.Item;
const { Option } = Select;

var bizuserid= ''
var supercompany= ''
var token= ''


class HomeIndex extends React.Component {

  state = {
    currentmonth:moment().format(monthdateFormat).toString(),
    companyprofile:{},
    datarequested:true,
    totalmemberaccounts:0,
    totalfemaleaccounts:0,
    totalmaleaccounts:0,
    totalloansthismonth:0,
    totalmembersavings:0,
    memberformvisible:false,
    transactformvisible:false,
    calculatorformvisible:false,
    saccos:[],
    loansformvisible:false,
    monthlyloanissuances:0,
    monthlyloanapplications:0,
    monthlyloanassessments:0,
    monthlyloanapprovals:0,
    dashboardlocked:false,
    utilitiesmodalvisible:false,
    userprofile:{},
    userrights:{},
    nonworkingday:{},
    branchdashboardvisible:false,
    branchaccounts:[],
    branchaccountnames:[],

    monthlywithdrawamounts : [],
    withdrawmonths:[],
    monthlydepositamounts : [],
    depositmonths:[],
    monthlyexpenseamounts:[],
    expensemonths:[],
    monthlyincomeamounts:[],
    incomemonths:[],
    monthlyissueamounts:[],
    issuemonths:[],

    monthlyprincipleamounts:[],
    principlemonths:[],

    monthlyinterestamounts:[],
    interestmonths:[],

    dailydepositamounts:[],
    dailydepositbranches:[],

    dailywithdrawamounts:[],
    dailywithdrawbranches:[],

    dailyexpenseamounts:[],
    dailyexpensebranches:[],

    dailyincomeamounts:[],
    dailyincomebranches:[],

    dailyissuanceamounts:[],
    dailyissuancebranches:[],

    dailyprincipleamounts:[],
    dailyprinciplebranches:[],

    dailyinterestamounts:[],
    dailyinterestbranches:[],

  };



  callback =(key) =>{
    console.log(key);
    console.log(this.state.yearlymonthlysales)
  }

  componentDidMount(){

    if(localStorage.getItem("supercompany")){
      supercompany= CryptoJS.AES.decrypt(localStorage.getItem("supercompany") , 'my-secret-key@123456').toString(CryptoJS.enc.Utf8)
    }else{
      supercompany= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }
    
    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }



   let form_data = new FormData();
   form_data.append('userid', bizuserid);
   
   //get the user profile here
   axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data,{
     headers: {
       'content-type': 'multipart/form-data'
     }
     })
   .then(res => {
       this.setState({
         userprofile:res.data
       })
   })


    axios.get(serverconfig.backendserverurl+`/api/supercompanies/${supercompany}`)
    .then(res => {
        this.setState({
          companyprofile:res.data
        })
    })


    axios.get(serverconfig.backendserverurl+`/api/saccos/?supercompany=${supercompany}`)
    .then(res => {
        this.setState({
          saccos:res.data
        })
        console.log(res.data)

        this.setState({datarequested:false})
    })


        ///////////////////////////////////////////////////////////////////////////////
    /// getting the expense amounts by category
    let form_data2 = new FormData();
    form_data2.append('supercompany',supercompany);
 
      //Now submit sale data to database
      axios.post(serverconfig.backendserverurl+'/customqueries/getaccountsbybranch',form_data2,{
        headers: {
          'content-type': 'multipart/form-data'
        }
          })
          .then(res =>{

              const branchaccounts = [];
              const branchaccountnames=[];

              JSON.parse(res.data.accountsreport).map(
                (item)=>{
                  branchaccounts.push(Number(item.accountstotal))
                  branchaccountnames.push(item.branchname)
                })

                this.setState({
                  branchaccounts:branchaccounts}) 

                this.setState({
                  branchaccountnames:branchaccountnames}) 
          } 
          )
          .catch(error => console.log(error)) 


        let form_data3 = new FormData();
        form_data3.append('supercompany',supercompany);
      
          //Now submit sale data to database
          axios.post(serverconfig.backendserverurl+'/customqueries/getinterbranchstatistics',form_data3,{
            headers: {
              'content-type': 'multipart/form-data'
            }
              })
              .then(res =>{
    
                  const monthlywithdrawamount = [];
                  const withdrawmonths=[];

                  const monthlydepositamounts = [];
                  const depositmonths=[];
    
                  const monthlyexpenseamounts = [];
                  const expensemonths=[];

                  const monthlyincomeamounts=[];
                  const incomemonths=[];

                  const monthlyissueamounts=[];
                  const issuemonths=[];

                  const monthlyprincipleamounts=[];
                  const principlemonths=[];
              
                  const monthlyinterestamounts=[];
                  const interestmonths=[];

                  const dailydepositamounts=[];
                  const dailydepositbranches=[];


                  const dailywithdrawamounts=[];
                  const dailywithdrawbranches=[];

                  const dailyexpenseamounts=[];
                  const dailyexpensebranches=[];

                  const dailyincomeamounts=[];
                  const dailyincomebranches=[];

                  const dailyissuanceamounts=[];
                  const dailyissuancebranches=[];

                  const dailyprincipleamounts=[];
                  const dailyprinciplebranches=[];

                  const dailyinterestamounts=[];
                  const dailyinterestbranches=[];

                  JSON.parse(res.data.dailyprinciplepayreport).map(
                    (item)=>{
                      dailyprincipleamounts.push(Number(item.amount))
                      dailyprinciplebranches.push(item.branch)
                    })


                  JSON.parse(res.data.dailyinterestpayreport).map(
                    (item)=>{
                      dailyinterestamounts.push(Number(item.amount))
                      dailyinterestbranches.push(item.branch)
                    })

    
                  JSON.parse(res.data.withdrawsreport).map(
                    (item)=>{
                      monthlywithdrawamount.push(Number(item.amount))
                      withdrawmonths.push(item.month)
                    })

                  JSON.parse(res.data.depositsreport).map(
                    (item)=>{
                      monthlydepositamounts.push(Number(item.amount))
                      depositmonths.push(item.month)
                    })

                  JSON.parse(res.data.expensesreport).map(
                    (item)=>{
                      monthlyexpenseamounts.push(Number(item.amount))
                      expensemonths.push(item.month)
                    })

                  JSON.parse(res.data.incomesreport).map(
                    (item)=>{
                      monthlyincomeamounts.push(Number(item.amount))
                      incomemonths.push(item.month)
                    })

                  JSON.parse(res.data.issuancesreport).map(
                    (item)=>{
                      monthlyissueamounts.push(Number(item.amount))
                      issuemonths.push(item.month)
                    })


                  JSON.parse(res.data.principlerepaymentreport).map(
                    (item)=>{
                      monthlyprincipleamounts.push(Number(item.amount))
                      principlemonths.push(item.month)
                    })

                  JSON.parse(res.data.interestrepaymentreport).map(
                    (item)=>{
                      monthlyinterestamounts.push(Number(item.amount))
                      interestmonths.push(item.month)
                    })                    

                  JSON.parse(res.data.dailydepositreport).map(
                    (item)=>{
                      dailydepositamounts.push(Number(item.amount))
                      dailydepositbranches.push(item.branch)
                    })  

                  JSON.parse(res.data.dailywithdrawreport).map(
                    (item)=>{
                      dailywithdrawamounts.push(Number(item.amount))
                      dailywithdrawbranches.push(item.branch)
                    })  

                  JSON.parse(res.data.dailyexpenditurereport).map(
                    (item)=>{
                      dailyexpenseamounts.push(Number(item.amount))
                      dailyexpensebranches.push(item.branch)
                    }) 


                  JSON.parse(res.data.dailyincomereport).map(
                    (item)=>{
                      dailyincomeamounts.push(Number(item.amount))
                      dailyincomebranches.push(item.branch)
                    }) 


                  JSON.parse(res.data.dailyissuancesreport).map(
                    (item)=>{
                      dailyissuanceamounts.push(Number(item.amount))
                      dailyissuancebranches.push(item.branch)
                    }) 

                  this.setState({dailyissuanceamounts:dailyissuanceamounts})
                  this.setState({dailyissuancebranches:dailyissuancebranches})

                  this.setState({monthlywithdrawamounts:monthlywithdrawamount})
                  this.setState({withdrawmonths:withdrawmonths})

                  this.setState({monthlydepositamounts:monthlydepositamounts})
                  this.setState({depositmonths:depositmonths}) 

                  this.setState({monthlyexpenseamounts:monthlyexpenseamounts})
                  this.setState({expensemonths:expensemonths}) 

                  this.setState({monthlyincomeamounts:monthlyincomeamounts})
                  this.setState({incomemonths:incomemonths})    

                  this.setState({monthlyissueamounts:monthlyissueamounts})
                  this.setState({issuemonths:issuemonths})    

                  this.setState({monthlyprincipleamounts:monthlyprincipleamounts})
                  this.setState({principlemonths:principlemonths})  

                  this.setState({monthlyinterestamounts:monthlyinterestamounts})
                  this.setState({interestmonths:interestmonths})  

                  this.setState({dailydepositamounts:dailydepositamounts})
                  this.setState({dailydepositbranches:dailydepositbranches})  

                  this.setState({dailywithdrawamounts:dailywithdrawamounts})
                  this.setState({dailywithdrawbranches:dailywithdrawbranches})  

                  this.setState({dailyexpenseamounts:dailyexpenseamounts})
                  this.setState({dailyexpensebranches:dailyexpensebranches})  

                  this.setState({dailyincomeamounts:dailyincomeamounts})
                  this.setState({dailyincomebranches:dailyincomebranches})  

                  this.setState({dailyprincipleamounts:dailyprincipleamounts})
                  this.setState({dailyprinciplebranches:dailyprinciplebranches})  

                  this.setState({dailyinterestamounts:dailyinterestamounts})
                  this.setState({dailyinterestbranches:dailyinterestbranches})  

              })
              .catch(error => console.log(error)) 

  }


//render branch based dashboar
displayBranchDashboard=(saccoid)=>{
    localStorage.setItem('sacco',CryptoJS.AES.encrypt(String(saccoid), 'my-secret-key@12345').toString());
    localStorage.setItem('dashboardstatus','branch');
  }


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ?(
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};


  render() {
    const  chartData1={
      labels:this.state.branchaccountnames,
      datasets:[{
        label:'Branch Accounts',
        data:this.state.branchaccounts,
        backgroundColor:[
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 1
      }]
    }


    const  monthlywithdrawschartData={
      labels:this.state.withdrawmonths,
      datasets:[{
        label:'Monthly Withdraws',
        data:this.state.monthlywithdrawamounts,
        backgroundColor:[
          '#fff',
         
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 2
      }]      
    }



    const  monthlydepositschartData={
      labels:this.state.depositmonths,
      datasets:[{
        label:'Monthly deposits',
        data:this.state.monthlydepositamounts,
        backgroundColor:[
          '#fff',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 2
      }]
          
    }


    const  monthlyexpenseschartData={
      labels:this.state.expensemonths,
      datasets:[{
        label:'Monthly expenses',
        data:this.state.monthlyexpenseamounts,
        backgroundColor:[
          '#fff',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 2
      }]
          
    }


    const  monthlyincomeschartData={
      labels:this.state.incomemonths,
      datasets:[{
        label:'Monthly income',
        data:this.state.monthlyincomeamounts,
        backgroundColor:[
          '#fff',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 2
      }]
          
    }


    const  monthlyissuancechartData={
      labels:this.state.issuemonths,
      datasets:[{
        label:'Monthly loan issuance',
        data:this.state.monthlyissueamounts,
        backgroundColor:[
          '#fff',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 2
      }]
          
    }


    const  monthlyinterestchartData={
      labels:this.state.interestmonths,
      datasets:[{
        label:'Monthly interest payment',
        data:this.state.monthlyinterestamounts,
        backgroundColor:[
          '#fff',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 2
      }]
          
    }


    const  monthlyprinciplechartData={
      labels:this.state.principlemonths,
      datasets:[{
        label:'Monthly principle payment',
        data:this.state.monthlyprincipleamounts,
        backgroundColor:[
          '#fff',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 2
      }]
          
    }


    const  dailydepositData={
      labels:this.state.dailydepositbranches,
      datasets:[{
        label:moment().format(dateFormat).toString()+ " Deposits",
        data:this.state.dailydepositamounts,
        backgroundColor:[
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
          ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 2
      }]
          
    }


    const  dailywithdrawData={
      labels:this.state.dailywithdrawbranches,
      datasets:[{
        label:moment().format(dateFormat).toString()+ " Withdraws",
        data:this.state.dailywithdrawamounts,
        backgroundColor:[
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
          ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 2
      }]
          
    }

    const  dailyexpenditureData={
      labels:this.state.dailyexpensebranches,
      datasets:[{
        label:moment().format(dateFormat).toString()+ " Expenses",
        data:this.state.dailyexpenseamounts,
        backgroundColor:[
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
          ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 2
      }]
          
    }


    const  dailyincomeData={
      labels:this.state.dailyincomebranches,
      datasets:[{
        label:moment().format(dateFormat).toString()+ " Incomes",
        data:this.state.dailyincomeamounts,
        backgroundColor:[
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
          ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 2
      }]
          
    }


    const  dailyissuanceData={
      labels:this.state.dailyissuancebranches,
      datasets:[{
        label:moment().format(dateFormat).toString()+ " Loan Issuance",
        data:this.state.dailyissuanceamounts,
        backgroundColor:[
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
          ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 2
      }]
          
    }


    const  dailyprincipleData={
      labels:this.state.dailyprinciplebranches,
      datasets:[{
        label:moment().format(dateFormat).toString()+ "Principle Loan Repayment",
        data:this.state.dailyprincipleamounts,
        backgroundColor:[
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
          ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 2
      }] 
    }


    const  dailyinterestData={
      labels:this.state.dailyinterestbranches,
      datasets:[{
        label:moment().format(dateFormat).toString()+ "Interest Loan Repayment",
        data:this.state.dailyinterestamounts,
        backgroundColor:[
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
          ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 2
      }] 
    }



    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
  
    }else{

        return(
          <div className="animated fadeIn">  
         
          <h5 style={{fontStyle:'oblique',fontWeight:'bolder'}}>{this.state.companyprofile.name} -BRANCHES DASHBOARD</h5>

          <Row>

          <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
          <Card
              hoverable
            >
              <Pie
                  data={chartData1}
                  options={{
                    maintainAspectRatio:true,
                    title:{
                      display:true,
                      text: 'Accounts No. by Branch',
                      fontSize:16
                    }
                  }}
                  />
            </Card>
          </Col>


          <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
          <Card
              hoverable
            >
              <Bar
                  data={dailydepositData}
                  options={{
                    maintainAspectRatio:true,
                    title:{
                      display:true,
                      text: 'Daily Deposits by Branch',
                      fontSize:16
                    }
                  }}
                  />
          
            </Card>
          </Col>


          <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
          <Card
              hoverable
            >
              <Bar
                  data={dailywithdrawData}
                  options={{
                    maintainAspectRatio:true,
                    title:{
                      display:true,
                      text: 'Daily Withdraws by Branch',
                      fontSize:16
                    }
                  }}
                  />
          
            </Card>
          </Col>


          <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
          <Card
              hoverable
            >
              <Bar
                  data={dailyexpenditureData}
                  options={{
                    maintainAspectRatio:true,
                    title:{
                      display:true,
                      text: 'Daily Expenses by Branch',
                      fontSize:16
                    }
                  }}
                  />
          
            </Card>
          </Col>

          <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
          <Card
              hoverable
            >
              <Bar
                  data={dailyincomeData}
                  options={{
                    maintainAspectRatio:true,
                    title:{
                      display:true,
                      text: 'Daily Incomes by Branch',
                      fontSize:16
                    }
                  }}
                  />
          
            </Card>
          </Col>

          <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
          <Card
              hoverable
            >
              <Bar
                  data={dailyissuanceData}
                  options={{
                    maintainAspectRatio:true,
                    title:{
                      display:true,
                      text: 'Daily Loan Issuance by Branch',
                      fontSize:16
                    }
                  }}
                  />
          
            </Card>
          </Col>

          <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
          <Card
              hoverable
            >
              <Bar
                  data={dailyprincipleData}
                  options={{
                    maintainAspectRatio:true,
                    title:{
                      display:true,
                      text: 'Daily Principle pay by Branch',
                      fontSize:16
                    }
                  }}
                  />
          
            </Card>
          </Col>

          <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
          <Card
              hoverable
            >
              <Bar
                  data={dailyinterestData}
                  options={{
                    maintainAspectRatio:true,
                    title:{
                      display:true,
                      text: 'Daily Interest pay by Branch',
                      fontSize:16
                    }
                  }}
                  />
          
            </Card>
          </Col>

          </Row>


          <h6 style={{fontStyle:'oblique',fontWeight:'bolder'}}>MONTHLY TRENDS</h6>
          
          <Row>



          <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
          <Card
              hoverable
            >
              <Line
                  data={monthlydepositschartData}
                  options={{
                    maintainAspectRatio:true,
                    title:{
                      display:true,
                      text: 'Deposit Amounts By Month',
                      fontSize:16
                    }
                  }}
                  />
          
            </Card>
          </Col>


          
          
          <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
          <Card
              hoverable
            >
              <Line
                  data={monthlywithdrawschartData}
                  options={{
                    maintainAspectRatio:true,
                    title:{
                      display:true,
                      text: 'Withdraw Amounts By Month',
                      fontSize:16
                    }
                  }}
                  />
          
            </Card>
          </Col>


         


          <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
          <Card
              hoverable
            >
              <Line
                  data={monthlyexpenseschartData}
                  options={{
                    maintainAspectRatio:true,
                    title:{
                      display:true,
                      text: 'Expense Amounts By Month',
                      fontSize:16
                    }
                  }}
                  />
          
            </Card>
          </Col>


          <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
          <Card
              hoverable
            >
              <Line
                  data={monthlyincomeschartData}
                  options={{
                    maintainAspectRatio:true,
                    title:{
                      display:true,
                      text: 'Income Amounts By Month',
                      fontSize:16
                    }
                  }}
                  />
          
            </Card>
          </Col>

          <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
          <Card
              hoverable
            >
              <Line
                  data={monthlyissuancechartData}
                  options={{
                    maintainAspectRatio:true,
                    title:{
                      display:true,
                      text: 'Loan Issuance Amounts By Month',
                      fontSize:16
                    }
                  }}
                  />
          
            </Card>
          </Col>

          <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
          <Card
              hoverable
            >
              <Line
                  data={monthlyprinciplechartData}
                  options={{
                    maintainAspectRatio:true,
                    title:{
                      display:true,
                      text: 'Loan Principle Payments Amounts By Month',
                      fontSize:16
                    }
                  }}
                  />
          
            </Card>
          </Col>


          <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
          <Card
              hoverable
            >
              <Line
                  data={monthlyinterestchartData}
                  options={{
                    maintainAspectRatio:true,
                    title:{
                      display:true,
                      text: 'Loan Interest Payments Amounts By Month',
                      fontSize:16
                    }
                  }}
                  />
          
            </Card>
          </Col>

          </Row>
          
          </div>
        )

    }
  
  }
}




export default HomeIndex; 
