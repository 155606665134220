import React,{ useRef } from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import * as serverconfig from '../serverconn'
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';

import {
  Col,
  Row,
} from 'reactstrap';
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var supercompany= ''
var bizuserid= ''

class ReportToPrint extends React.Component {

    state = {
      companyprofile:{},
    };
  
    componentDidMount(){
      if(localStorage.getItem("bizuserid")){
         bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      
      }else{
         bizuserid= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }

      if(localStorage.getItem("supercompany")){
        supercompany= CryptoJS.AES.decrypt(localStorage.getItem("supercompany") , 'my-secret-key@123456').toString(CryptoJS.enc.Utf8)
      }else{
        supercompany= ''
      }


      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      axios.get(`${serverconfig.backendserverurl}/api/supercompanies/${supercompany}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
  
      })

  
    }
  
    render() {
      return (
        <div style={{padding:20}}>
 <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>
                  <h5>Country: {this.state.companyprofile.country} || Currency: {this.state.companyprofile.currency}</h5>
                
                  </Col>

       </Row>

             <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>INTERBRANCH MONEY TRANSFERS REPORT  FROM: {this.props.dateone}  TO: {this.props.datetwo} BETWEEN {this.props.receiving_branch} AND {this.props.sending_branch}</h3>

             <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Sending Branch</th>
                          <th>Receiving Branch</th>
                          <th>Sending Account</th>
                          <th>Receiving Account</th>
                          <th>Transfer Reason</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.props.sheetitems.map(
                        (item)=>(
                          <tr>
                          <td>{item.date}</td>
                          <td>{item.sending_branch}</td>
                          <td>{item.receiving_branch}</td>
                          <td>{item.sending_account}</td>
                          <td>{item.receiving_account}</td>
                          <td>{item.transfer_reason}</td>
                          <td>{<CurrencyFormat value={item.transfer_amount} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalAmount} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>
                    </tbody>
                </reactstrp.Table>  
        </div>
      );
    }
  }


class GeneralInterBranchMoneyTransfersReportView extends React.Component {

  state = {
    companyprofile:{},
    sheetitems:[],
    dateone:'',
    datetwo:'',
    entrant:'',
    entrantname:'',
    totaldebit:0,
    totalcredit:0,
    datarequested:false,
    branches:[],
    selectedsendingbranch:'',
    selectedreceivingbranch:'',
    totalAmount:0,
    receivingbranchname:'',
    sendingbranchname:''
   };



   componentDidMount(){
    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   
   }else{
      bizuserid= ''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

   if(localStorage.getItem("supercompany")){
    supercompany= CryptoJS.AES.decrypt(localStorage.getItem("supercompany") , 'my-secret-key@123456').toString(CryptoJS.enc.Utf8)
    }else{
      supercompany= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


 
      //get branches
      axios.get(`${serverconfig.backendserverurl}/api/saccos/?supercompany=${supercompany}`)
      .then(res => {  
          this.setState({
            branches: res.data
          })
      })

    

  }
 
   handledatechange= (date, dateString) =>{
     this.setState({ dateone: dateString[0]});
     this.setState({ datetwo: dateString[1]});
   } 

   handleentrantChange= (e) => this.setState({ entrant: e});

 
   //search sales method
   searchSales=()=>{
      let form_data = new FormData();
      form_data.append('dateone', this.state.dateone);
      form_data.append('datetwo', this.state.datetwo);
      form_data.append('selectedreceivingbranch', this.state.selectedreceivingbranch);
      form_data.append('selectedsendingbranch', this.state.selectedsendingbranch);

      if(this.state.dateone===''||this.state.datetwo===''){
        alert("Please dates missing")
      }else if(this.state.selectedreceivingbranch===this.state.selectedsendingbranch){
        alert("Receiving branch cant be the same as the sending branch")
      }else{

        this.setState({datarequested:true})

        //Now submit sale data to database
        axios.post(serverconfig.backendserverurl+'/customqueries/getinterbranchmoneytransfersreport', form_data,{
          headers: {
            'content-type': 'multipart/form-data'
          }
          })
          .then(res =>{
            this.setState({datarequested:false})

              this.setState({sheetitems:JSON.parse(res.data.report)})
              this.setState({totalAmount:res.data.totalAmount})

          } 
          )
          .catch(error => console.log(error))  


 
      }

    }


//handle branch change
handleselectedsendingbranchChange=(e)=>{
  this.setState({selectedsendingbranch:e})
  this.setState({datarequested:true})

  axios.get(`${serverconfig.backendserverurl}/api/saccos/${e}`)
  .then(res => {  
    this.setState({sendingbranchname:res.data.branchname})
    this.setState({datarequested:false})

  })

}

handleselectedreceivingbranchChange=(e)=>{
  this.setState({selectedreceivingbranch:e})
  this.setState({datarequested:true})

  axios.get(`${serverconfig.backendserverurl}/api/saccos/${e}`)
  .then(res => {  
    this.setState({receivingbranchname:res.data.branchname})
    this.setState({datarequested:false})

  })

}



   ////////////////////////////////////
  // USER SEARCH SELECT METHODS
  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }
 

   ////////////////////////////////////////////
   // RENDERING METHOD HERE
   render() {
       
       if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
          <Spin indicator={antIcon} />
          </div>
        )
  
      }else{

        return(
          <div>
            <div style={{display: "none"}}>
               &nbsp;&nbsp;
              <ReportToPrint
              totalAmount={this.state.totalAmount} 
              dateone={this.state.dateone}
              datetwo={this.state.datetwo}
              sheetitems={this.state.sheetitems}
              transactiontype={this.state.transactiontype}
              receiving_branch={this.state.receivingbranchname}
              sending_branch={this.state.sendingbranchname}

              ref={el => (this.componentRef = el)} /> 
            </div>

  
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
              <Panel header="INTERBRANCH MONEY TRANSFERS REPORT" key="1">
              <Form  layout="inline" >
 
              <FormItem label="Sending Branch">
                  <Select 
                  placeholder="Sending Branch Name" 
                  style={{ width:200 }} 
                  value={this.state.selectedsendingbranch}
                  onChange={this.handleselectedsendingbranchChange} 
                  showSearch
                  optionFilterProp="children"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}
                                        
                  >
                    {this.state.branches.map(
                      (accnt)=>(
                        <Option value={accnt.id} >{accnt.branchname}</Option>
                      ))}
                  </Select>
              </FormItem>

              <FormItem label="Receiving Branch">
                  <Select 
                  placeholder="Receiving Branch Name" 
                  style={{ width:200 }} 
                  value={this.state.selectedreceivingbranch}
                  onChange={this.handleselectedreceivingbranchChange} 
                  showSearch
                  optionFilterProp="children"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}                                        
                  >
                    {this.state.branches.map(
                      (accnt)=>(
                        <Option value={accnt.id} >{accnt.branchname}</Option>
                      ))}
                  </Select>
              </FormItem>

            <FormItem label="Date Range">
                <RangePicker defaultValue={moment()} onChange={this.handledatechange} format={dateFormat} />
            </FormItem>

            <FormItem>
            <Button onClick={this.searchSales} type="primary" htmlType="button">Search</Button>
            </FormItem> 
  
             </Form>
  
             <br></br>
             <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Sending Branch</th>
                          <th>Receiving Branch</th>
                          <th>Sending Account</th>
                          <th>Receiving Account</th>
                          <th>Transfer Reason</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.sheetitems.map(
                        (item)=>(
                          <tr>
                          <td>{item.date}</td>
                          <td>{item.sending_branch}</td>
                          <td>{item.receiving_branch}</td>
                          <td>{item.sending_account}</td>
                          <td>{item.receiving_account}</td>
                          <td>{item.transfer_reason}</td>
                          <td>{<CurrencyFormat value={item.transfer_amount} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalAmount} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>
                    </tbody>
                </reactstrp.Table>  

                <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}>
                <ReactToPrint
                  trigger={() => 
                  <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                  Print
                  </Button>
                  }
                  content={() => this.componentRef}
                  />
              </div>

 
              </Panel>
              </Collapse>
  
          </div>
      )        

      }

   }
}

export default GeneralInterBranchMoneyTransfersReportView; 
