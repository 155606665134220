import React from 'react';
import { Form, Input, Button,Select,DatePicker,Spin,Image, Card,Divider,Result, message } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import * as serverconfig from '../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import { UserOutlined, LockOutlined, LoadingOutlined,MailOutlined } from '@ant-design/icons';
var CryptoJS = require("crypto-js");


const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


var token= ''
var sacco= ''
var bizuserid= ''


class MemberForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    accounttypes:[],
    idtypes:[],
    sur_name:'' ,  
    middle_name:'',
    given_name:'',
    nationality:'',
    ID_type:'',
    ID_number:'',
    date_Of_Birth:'',
    Email_Address:'',
    P_O_Box:'',
    Area_Of_Residence:'',
    District:'',
    RegisteredPhoneNumber1:'',
    RegisteredPhoneNumber2:'',
    next_of_kin:'',
    Next_Of_Kin_Physical_Address:'',
    relationship:'',
    Photo:null,
    Signature:null,
    Date_Of_Joining:'',
    account_type:'',
    date_of_appointment:'',
    status:'',
    staff_number:'',
    reference_one:'',
    Referee1PhoneNumber:'',
    datarequested:true,
    accountnumber:'',
    photofile:null,
    signaturefile:null,
    marital_status:'',
    own_residence:'',
    tribe:'',
    recommender:'',
    language1:'',
    language2:'',
    gender:'',
    any_other_account:'',
    other_account_number:'',
    other_bank:'',
    shares:0,
    title:'',
    datasubmittedsuccessfully:false,
    saccopackageobj:{},
    members:[],
    saccoboject:{}
  }

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
      bizuserid= ''
    }


    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/accounttypes/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          accounttypes:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/idtypes/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          idtypes:res.data
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          members:res.data
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/saccos/${sacco}`)
    .then(res => {
        this.setState({
          saccoboject:res.data
        })

        //get the sacco package here
        axios.get(serverconfig.backendserverurl+`/api/packages/${res.data.package}`)
        .then(res => {
            this.setState({
              saccopackageobj:res.data
            })

            this.setState({datarequested:false})

        })

    })

}


  //submit button pressed
  handleFormSubmit=(event) =>{
    console.log(this.state.Photo)
    console.log(this.state.Signature)

    if (this.state.RegisteredPhoneNumber1===''){
      message.info("Phone Number missing")
    }else{
      this.setState({datarequested:true})

      let form_data = new FormData();
      form_data.append('date', this.state.date);
      form_data.append('SurName', this.state.sur_name);
      form_data.append('MiddleName', this.state.middle_name);
      form_data.append('OtherName', this.state.given_name);
      form_data.append('Nationality', this.state.nationality);
      form_data.append('IDType', this.state.ID_type);
      form_data.append('IDNumber', this.state.ID_number);
      form_data.append('DateOfBirth', this.state.date_Of_Birth);
  
      form_data.append('EmailAddress', this.state.Email_Address);
      form_data.append('POBox', this.state.P_O_Box);
      form_data.append('AreaOfResidence', this.state.Area_Of_Residence);
      form_data.append('District', this.state.District);
      form_data.append('RegisteredPhoneNumber1', this.state.RegisteredPhoneNumber1);
      form_data.append('RegisteredPhoneNumber2', this.state.RegisteredPhoneNumber2);
  
      form_data.append('next_of_kin', this.state.next_of_kin);
      form_data.append('NextOfKinPhysicalAddress', this.state.Next_Of_Kin_Physical_Address);
      form_data.append('relationship', this.state.relationship);
      form_data.append('DateOfJoining', this.state.Date_Of_Joining);
  
      form_data.append('accountType', this.state.account_type);
      form_data.append('gender', this.state.gender);
      form_data.append('marital_status', this.state.marital_status);
      form_data.append('title', this.state.title);
      form_data.append('AccountNumber', this.state.accountnumber);
  
      form_data.append('own_residence', this.state.own_residence);
      form_data.append('tribe', this.state.tribe);
      form_data.append('recommender', this.state.recommender);
      form_data.append('how_did_you_know_sacco', '');
  
      form_data.append('language1', this.state.language1);
      form_data.append('language2', this.state.language2);
      form_data.append('any_other_account', this.state.any_other_account);
      form_data.append('other_account_number', this.state.other_account_number);
      form_data.append('other_bank', this.state.other_bank);
      form_data.append('shares', this.state.shares);
      form_data.append('sacco', sacco);
      form_data.append('user', bizuserid);

      this.state.Photo==null?
      console.log("No profile file")
      :
      form_data.append('Photo', this.state.Photo, this.state.Photo?this.state.Photo.name:"");
  
      this.state.Signature==null?
      console.log("No signature file")
      :
      form_data.append('Signature', this.state.Signature, this.state.Signature?this.state.Signature.name:"");
  
      console.log(form_data)
  
      axios.post(serverconfig.backendserverurl+'/api/members/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res =>{
        console.log(res.data)


        if(this.state.saccoboject.automated_accounts){

            let form_data = new FormData();
            form_data.append('date', this.state.date);
            form_data.append('SurName', this.state.sur_name);
            form_data.append('MiddleName', this.state.middle_name);
            form_data.append('OtherName', this.state.given_name);
            form_data.append('RegisteredPhoneNumber1', this.state.RegisteredPhoneNumber1);
            form_data.append('accountType', this.state.account_type);
            form_data.append('sacco', sacco);
            ///make a post request now
              axios.post(serverconfig.backendserverurl+'/customqueries/upodatedautomatedaccount', form_data, {
                headers: {
                  'content-type': 'multipart/form-data'
                }
              })
            .then(res => this.setState({datarequested:false}),
            this.setState({datasubmittedsuccessfully:true})
            )
            .catch(error => console.log(error))

        }else{

          this.setState({datarequested:false})
          //window.location.reload(false)
          this.setState({datasubmittedsuccessfully:true})

        }

      })
      .catch(error => console.log(error))

    }

  }

  handlesur_nameChange= (e) => this.setState({ sur_name: e.target.value});
  handlemiddle_nameChange= (e) => this.setState({ middle_name: e.target.value});
  handlegiven_nameChange= (e) => this.setState({ given_name: e.target.value});
  handlenationalityChange= (e) => this.setState({ nationality: e.target.value});
  handleID_typeChange= (e) => this.setState({ ID_type: e});
  handletitleChange= (e) => this.setState({ title: e});

  handleID_numberChange= (e) => this.setState({ ID_number: e.target.value});
  handledate_Of_BirthChange= (date, dateString) => this.setState({ date_Of_Birth: dateString});

  handleEmail_AddressChange= (e) => this.setState({ Email_Address: e.target.value});
  handleP_O_BoxChange= (e) => this.setState({ P_O_Box: e.target.value});
  handleArea_Of_ResidenceChange= (e) => this.setState({ Area_Of_Residence: e.target.value});
  handleDistrictChange= (e) => this.setState({ District: e.target.value});
  handleRegisteredPhoneNumber1Change= (e) => this.setState({ RegisteredPhoneNumber1: e});

  handleRegisteredPhoneNumber2Change= (e) => this.setState({ RegisteredPhoneNumber2: e});
  handlenext_of_kinChange= (e) => this.setState({ next_of_kin: e.target.value});
  handleNext_Of_Kin_Physical_AddressChange= (e) => this.setState({ Next_Of_Kin_Physical_Address: e.target.value});
  handlerelationshipChange= (e) => this.setState({ relationship: e.target.value});
  handlePhotoChange= (e) =>{
    this.setState({ Photo: e.target.files[0]});

    if(e.target.files[0]){
      this.setState({
        photofile: URL.createObjectURL(e.target.files[0])
      })
    }

  } 

  handleSignatureChange= (e) =>{
    this.setState({ Signature: e.target.files[0]});
    if(e.target.files[0]){
      this.setState({
        signaturefile: URL.createObjectURL(e.target.files[0])
      })
    }


  } 

  handleDate_Of_JoiningChange= (date, dateString) => this.setState({ Date_Of_Joining: dateString});
  handleaccount_typeChange= (e) => this.setState({ account_type: e});
  handledate_of_appointmentChange= (date, dateString) => this.setState({ date_of_appointment: dateString});

  handlestatusChange= (e) => this.setState({ status: e});
  handlemarital_statusChange= (e) => this.setState({ marital_status: e});
  handleown_residenceChange= (e) => this.setState({ own_residence: e});

  handlestaff_numberChange= (e) => this.setState({ staff_number: e.target.value});
  handlereference_oneChange= (e) => this.setState({ reference_one: e.target.value});
  handleReferee1PhoneNumberChange= (e) => this.setState({ Referee1PhoneNumber: e});

  handleaccountnumberChange= (e) => this.setState({ accountnumber: e.target.value});
  handletribeChange= (e) => this.setState({ tribe: e.target.value});
  handlerecommenderChange= (e) => this.setState({ recommender: e.target.value});
  handlelanguage1Change= (e) => this.setState({ language1: e.target.value});
  handlelanguage2Change= (e) => this.setState({ language2: e.target.value});
  handlegenderChange= (e) => this.setState({ gender: e});

  handleother_account_numberChange= (e) => this.setState({ other_account_number: e.target.value});
  handleany_other_accountChange= (e) => this.setState({ any_other_account: e});
  handleother_bankChange= (e) => this.setState({ other_bank: e.target.value});
  handlesharesChange= (e) => this.setState({ shares: e.target.value});

  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Successfully Created New Member "
            subTitle="Member details will be further reviewed if need be"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{

          if(this.state.members.length>=this.state.saccopackageobj.memberaccountslimit){
            return (
              <div>
                <h4 style={{color:'red'}}>You have reached members limit.Please contact adminstrators for upgrade of package.Thanx</h4>
              </div>
            );

          }else{

            return (
              <div>
                <Form
                    onFinish={(event) => this.handleFormSubmit(event)}
                >
                <h3>Bio Data Section</h3>
                  <FormItem label="Sur Name"
                      name="surname"
                      rules={[
                        {
                          required: true,
                          message: 'Please input sur name',
                        },
                      ]}          
                  >
                    <Input  placeholder="Put a name here." value={this.state.sur_name} onChange={this.handlesur_nameChange} />
                  </FormItem>
        
                  <FormItem label="Middle Name">
                    <Input  placeholder="Put a name here." value={this.state.middle_name} onChange={this.handlemiddle_nameChange} />
                  </FormItem>
        
                  <FormItem label="Given Name"
                      name="givenname"
                      rules={[
                        {
                          required: true,
                          message: 'Please input given name',
                        },
        
                      ]}           
                  >
                    <Input  placeholder="Put a name here." value={this.state.given_name} onChange={this.handlegiven_nameChange} />
                  </FormItem>
      
                  <FormItem label="Gender"
                     name="gender"
                     rules={[
                       {
                         required: true,
                         message: 'Please select',
                       },
                     ]}           
                  
                  >
                      <Select placeholder="Gender" style={{ width: 120 }} value={this.state.gender} onChange={this.handlegenderChange} >
                            <Option value="Male">Male</Option>
                            <Option value="Female">Female</Option>
                      </Select>
                  </FormItem>
      
        
                  <FormItem label="Nationality"
                      name="nationality"
                      rules={[
                        {
                          required: true,
                          message: 'Please input nationality',
                        },
                      ]}            
                  >
                    <Input  placeholder="Nationality." value={this.state.nationality} onChange={this.handlenationalityChange} />
                  </FormItem>
        
        
                  <FormItem label="ID Type"
                      name="idtype"
                      rules={[
                        {
                          required: true,
                          message: 'Please select id type',
                        },
                      ]}           
                  >
                      <Select placeholder="Id Type" style={{  }} value={this.state.ID_type} onChange={this.handleID_typeChange} >
                        {this.state.idtypes.map(
                          (type)=>(
                            <Option value={type.id}>{type.id_type_name}</Option>
                          ))}
                      </Select>
                  </FormItem>
        
                  <FormItem label="ID Number">
                    <Input  placeholder="ID Number." value={this.state.ID_number} onChange={this.handleID_numberChange} />
                  </FormItem>
        
                  <FormItem label="Date of Birth"
                      name="dateofbirth"
                      rules={[
                        {
                          required: true,
                          message: 'Please select date of birth',
                        },
                      ]}           
                  >
                        <DatePicker onChange={this.handledate_Of_BirthChange} format={dateFormat} />
                  </FormItem>
      
                  <FormItem label="Tribe">
                    <Input  placeholder="Tribe." value={this.state.tribe} onChange={this.handletribeChange} />
                  </FormItem>
      
                  <FormItem label="Language one">
                    <Input  placeholder="Language one." value={this.state.language1} onChange={this.handlelanguage1Change} />
                  </FormItem>
      
                  <FormItem label="Language two">
                    <Input  placeholder="Language two." value={this.state.language2} onChange={this.handlelanguage2Change} />
                  </FormItem>
      
                  <div style={{display:'flex',flexDirection:'row'}}>
                  <FormItem label="Member Photo" style={{marginRight:3}}>
                        <Input type="file" accept="image/png, image/jpeg" placeholder="Member Photo"  onChange={this.handlePhotoChange}/>
                  </FormItem> 
      
                  {this.state.Photo?
                  <Image
                  width={50}
                  height={50}
                  src={this.state.photofile}    
                  />
                  :
                  null
                  }
      
                  </div>
      
      
                    <div style={{display:'flex',flexDirection:'row'}}>
                    <FormItem label="Member Signature" style={{marginRight:3}}>
                        <Input type="file" accept="image/png, image/jpeg" placeholder="Member Signature"  onChange={this.handleSignatureChange}/>
                    </FormItem> 
                    {this.state.Signature?
                      <Image
                      width={50}
                      height={50}
                      src={this.state.signaturefile}    
                      />
                      :
                      null
                      }
                    </div>
      
       
        
        
                  <h3>Contact Section</h3>
      
                  <FormItem label="Email Address"
                      name="emailaddress"
                      rules={[
                        {
                          required: false,
                          message: 'Please input mail',
                        },
                        {
                          type: 'email',
                          message: 'The input is not valid E-mail!',
                        },
                      ]}            
                  
                  >
                  <Input  type="mail" 
                          prefix={<MailOutlined className="site-form-item-icon" />} 
                          placeholder="Email"
                          value={this.state.Email_Address} 
                          onChange={this.handleEmail_AddressChange} />
                  </FormItem>
        
                  <FormItem label="Box Number">
                    <Input placeholder="box number." value={this.state.P_O_Box} onChange={this.handleP_O_BoxChange} />
                  </FormItem>
        
                  <FormItem label="Area of Residence"
                      name="areaofresidence"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter residence',
                        },
                      ]}           
                  >
                    <Input placeholder="Area of residence." value={this.state.Area_Of_Residence} onChange={this.handleArea_Of_ResidenceChange} />
                  </FormItem>
        
                  <FormItem label="District"
                     name="district"
                     rules={[
                       {
                         required: true,
                         message: 'Please enter district of residence',
                       },
                     ]}           
                  >
                    <Input placeholder="District." value={this.state.District} onChange={this.handleDistrictChange} />
                  </FormItem>
        
                  <FormItem label="Phone One"                    
                  >
                  <PhoneInput
                        countrySelectProps={{ unicodeFlags: true }}
                        defaultCountry="UG"
                        placeholder="Enter phone number"
                        value={this.state.RegisteredPhoneNumber1} onChange={this.handleRegisteredPhoneNumber1Change}/>
                   </FormItem> 
        
                   <FormItem label="Phone Two">
                   <PhoneInput
                        countrySelectProps={{ unicodeFlags: true }}
                        defaultCountry="UG"
                        placeholder="Enter phone number"
                        value={this.state.RegisteredPhoneNumber2} onChange={this.handleRegisteredPhoneNumber2Change}/>
                   </FormItem>
      
                   <h3>Personal Status Section</h3>
                   <FormItem label="Title"
                     name="title"
                     rules={[
                       {
                         required: true,
                         message: 'Please select',
                       },
                     ]}           
                  
                  >
                      <Select placeholder="Title" style={{ width: 120 }} value={this.state.title} onChange={this.handletitleChange} >
                            <Option value="Mrs">Mrs</Option>
                            <Option value="Mr">Mr</Option>
                            <Option value="Miss">Miss</Option>
                      </Select>
                  </FormItem>
      
                  <FormItem label="Marital Status"
                     name="marital_status"
                     rules={[
                       {
                         required: true,
                         message: 'Please select',
                       },
                     ]}           
                  
                  >
                      <Select placeholder="Title" style={{ width: 120 }} value={this.state.marital_status} onChange={this.handlemarital_statusChange} >
                            <Option value="Married">Married</Option>
                            <Option value="Engaged">Engaged</Option>
                            <Option value="Single">Single</Option>
                      </Select>
                  </FormItem>
      
                  <FormItem label="Own Residence?"
                     name="own_residence"
                     rules={[
                       {
                         required: true,
                         message: 'Please select',
                       },
                     ]}           
                  
                  >
                      <Select placeholder="Title" style={{ width: 120 }} value={this.state.own_residence} onChange={this.handleown_residenceChange} >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                      </Select>
                  </FormItem>
      
      
                   <h3>Next of Kin Section</h3>
      
                  <FormItem label="Next of kin">
                    <Input placeholder="Next of kin." value={this.state.next_of_kin} onChange={this.handlenext_of_kinChange} />
                  </FormItem>
        
                  <FormItem label="Next of kin Address">
                    <Input placeholder="Next of Kin Address." value={this.state.Next_Of_Kin_Physical_Address} onChange={this.handleNext_Of_Kin_Physical_AddressChange} />
                  </FormItem>
        
                  <FormItem label="Relationship">
                    <Input placeholder="Next of kin Relationship." value={this.state.relationship} onChange={this.handlerelationshipChange} />
                  </FormItem>
        
      
                  <h3>Account Section</h3>
                  <FormItem label="Account Type"
                     name="accountype"
                     rules={[
                       {
                         required: true,
                         message: 'Please enter account Type',
                       },
                     ]}           
                  >
                      <Select placeholder="Account Type" style={{  }} value={this.state.account_type} onChange={this.handleaccount_typeChange} >
                        {this.state.accounttypes.map(
                          (stf)=>(
                            <Option value={stf.id}>{stf.account_type_name}</Option>
                          ))}
                      </Select>
                  </FormItem>
      

                    {

                      this.state.saccoboject.automated_accounts===true?
                      null
                      :
                      <FormItem label="Account Number"
                      name="accountnumber"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter account Number'
                        },
                      ]} 
                    
                      >
                        <Input placeholder="Account Number." value={this.state.accountnumber} onChange={this.handleaccountnumberChange} />
                      </FormItem>

                    }


                  <FormItem label="Date of Joining">
                        <DatePicker onChange={this.handleDate_Of_JoiningChange} format={dateFormat}/>
                  </FormItem>
                
        
                  <FormItem label="Recommender">
                    <Input placeholder="Who recommended you to join sacco." value={this.state.recommender} onChange={this.handlerecommenderChange} />
                  </FormItem>
                  
                  <FormItem label="Any Other Account"
                    name="anyotheraccount"
                    rules={[
                      {
                        required: true,
                        message: 'Please select',
                      },
                    ]}
                  
                  >
                      <Select placeholder="Any Other Account" style={{ width: 120 }} value={this.state.any_other_account} onChange={this.handleany_other_accountChange} >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                      </Select>            
                  </FormItem>   
      
                  <FormItem label="Other Account No">
                    <Input placeholder="Other Account Number." value={this.state.other_account_number} onChange={this.handleother_account_numberChange} />
                  </FormItem>
      
                  <FormItem label="Other Bank">
                    <Input placeholder="Other Bank." value={this.state.other_bank} onChange={this.handleother_bankChange} />
                  </FormItem>
      
                  <FormItem >
                    <Button  type='primary' htmlType="submit">Submit</Button>
                  </FormItem>
      
                </Form>
              </div>
            );

          }



       

      }

    }
    
  }

}

export default MemberForm;



