import React,{ useRef } from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import * as serverconfig from '../serverconn'
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';

import {
  Col,
  Row,
} from 'reactstrap';
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var supercompany= ''
var bizuserid= ''

class ReportToPrint extends React.Component {

    state = {
      companyprofile:{},
    };
  
    componentDidMount(){
      if(localStorage.getItem("bizuserid")){
         bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      
      }else{
         bizuserid= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }

      if(localStorage.getItem("supercompany")){
        supercompany= CryptoJS.AES.decrypt(localStorage.getItem("supercompany") , 'my-secret-key@123456').toString(CryptoJS.enc.Utf8)
      }else{
        supercompany= ''
      }


      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      axios.get(`${serverconfig.backendserverurl}/api/supercompanies/${supercompany}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
  
      })

  
    }
  
    render() {
      return (
        <div style={{padding:20}}>
          <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>
                  <h5>Country: {this.state.companyprofile.country} || Currency: {this.state.companyprofile.currency}</h5>
                
                  </Col>

       </Row>



             <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>INTERBRANCH { this.props.transactiontype==="deposit"?"DEPOSIT":"WITHDRAW"} TRANSACTIONS REPORT  FROM: {this.props.dateone}  TO: {this.props.datetwo}</h3>

             <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Account</th>
                          <th>Trans Type</th>
                          <th>Host Branch</th>
                          <th>Dependant Branch</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.props.sheetitems.map(
                        (item)=>(
                          <tr>
                          <td>{item.date}</td>
                          <td>{item.account}</td>
                          <td>{item.transaction_type}</td>
                          <td>{item.hostbranch}</td>
                          <td>{item.dependantbranch}</td>
                          <td>{<CurrencyFormat value={item.amount} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalAmount} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>
                    </tbody>
                </reactstrp.Table>
        </div>
      );
    }
  }


class GeneralInterBranchTransactionReportView extends React.Component {

  state = {
    companyprofile:{},
    sheetitems:[],
    dateone:'',
    datetwo:'',
    entrant:'',
    entrantname:'',
    totaldebit:0,
    totalcredit:0,
    datarequested:false,
    branches:[],
    selectedhostbranch:'',
    selecteddependantbranch:'',
    transactiontype:'',
    totalAmount:0

   };

   componentDidMount(){
    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   
   }else{
      bizuserid= ''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

   if(localStorage.getItem("supercompany")){
    supercompany= CryptoJS.AES.decrypt(localStorage.getItem("supercompany") , 'my-secret-key@123456').toString(CryptoJS.enc.Utf8)
  }else{
    supercompany= ''
  }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


      //get branches
      axios.get(`${serverconfig.backendserverurl}/api/saccos/?supercompany=${supercompany}`)
      .then(res => {  
          this.setState({
            branches: res.data
          })
      })
  
    

  }
 
   handledatechange= (date, dateString) =>{
     this.setState({ dateone: dateString[0]});
     this.setState({ datetwo: dateString[1]});
   } 

   handleentrantChange= (e) => this.setState({ entrant: e});

 
   //search sales method
   searchSales=()=>{
      let form_data = new FormData();
      form_data.append('dateone', this.state.dateone);
      form_data.append('datetwo', this.state.datetwo);
      form_data.append('selecteddependantbranch', this.state.selecteddependantbranch);
      form_data.append('selectedhostbranch', this.state.selectedhostbranch);
      form_data.append('transactiontype', this.state.transactiontype);

      if(this.state.transactiontype===''||this.state.dateone===''||this.state.datetwo===''){
        alert("Please select transaction type or dates missing")
      }else if(this.state.selecteddependantbranch===this.state.selectedhostbranch){
        alert("Dependant branch cant be the same as the host branch")
      }else{

        this.setState({datarequested:true})

        //Now submit sale data to database
        axios.post(serverconfig.backendserverurl+'/customqueries/getinterbranchtransactionsreport', form_data,{
          headers: {
            'content-type': 'multipart/form-data'
          }
          })
          .then(res =>{
            this.setState({datarequested:false})
  
              this.setState({sheetitems:JSON.parse(res.data.report)})
              this.setState({totalAmount:res.data.totalAmount})

          } 
          )
          .catch(error => console.log(error))  

        
 
      }




    }


//handle branch change
handleselectedhostbranchChange=(e)=>{
  this.setState({selectedhostbranch:e})

}

handleselecteddependantbranchChange=(e)=>{
  this.setState({selecteddependantbranch:e})
}

handletransactiontypeChange=(e)=>{
  this.setState({transactiontype:e})

}


   ////////////////////////////////////
  // USER SEARCH SELECT METHODS
  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }
 

   ////////////////////////////////////////////
   // RENDERING METHOD HERE
   render() {
       
   
       if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
          <Spin indicator={antIcon} />
          </div>
        )
  
      }else{

        return(
          <div>
            <div style={{display: "none"}}>
               &nbsp;&nbsp;
              <ReportToPrint
              totalAmount={this.state.totalAmount} 
              dateone={this.state.dateone}
              datetwo={this.state.datetwo}
              sheetitems={this.state.sheetitems}
              transactiontype={this.state.transactiontype}
              ref={el => (this.componentRef = el)} /> 
            </div>
  
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
              <Panel header="INTERBRANCH TRANSACTIONS REPORT" key="1">
              <Form  layout="inline" >

              <FormItem label="Transaction Type">
                  <Select 
                  placeholder="Transaction Type" 
                  style={{ width:200 }} 
                  value={this.state.transactiontype}
                  onChange={this.handletransactiontypeChange} 
                  showSearch
                  optionFilterProp="children"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}                     
                  >
                        <Option value={'deposit'}>Deposit</Option>
                        <Option value={'withdraw'}>Withdraw</Option>
                  </Select>
              </FormItem>

 
              <FormItem label="Host Branch">
                  <Select 
                  placeholder="Host Branch Name" 
                  style={{ width:200 }} 
                  value={this.state.selectedhostbranch}
                  onChange={this.handleselectedhostbranchChange} 
                  showSearch
                  optionFilterProp="children"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}
                                        
                  >
                    {this.state.branches.map(
                      (accnt)=>(
                        <Option value={accnt.id}>{accnt.branchname}</Option>
                      ))}
                  </Select>
              </FormItem>

              <FormItem label="Dependant Branch">
                  <Select 
                  placeholder="Dependant Branch Name" 
                  style={{ width:200 }} 
                  value={this.state.selecteddependantbranch}
                  onChange={this.handleselecteddependantbranchChange} 
                  showSearch
                  optionFilterProp="children"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}
                                        
                  >
                    {this.state.branches.map(
                      (accnt)=>(
                        <Option value={accnt.id}>{accnt.branchname}</Option>
                      ))}
                  </Select>
              </FormItem>

            <FormItem label="Date Range">
                <RangePicker defaultValue={moment()} onChange={this.handledatechange} format={dateFormat} />
            </FormItem>

            <FormItem>
            <Button onClick={this.searchSales} type="primary" htmlType="button">Search</Button>
            </FormItem> 
  
             </Form>
  
             <br></br>
             <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Account</th>
                          <th>Trans Type</th>
                          <th>Host Branch</th>
                          <th>Dependant Branch</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.sheetitems.map(
                        (item)=>(
                          <tr>
                          <td>{item.date}</td>
                          <td>{item.account}</td>
                          <td>{item.transaction_type}</td>
                          <td>{item.hostbranch}</td>
                          <td>{item.dependantbranch}</td>
                          <td>{<CurrencyFormat value={item.amount} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalAmount} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>
                    </tbody>
                </reactstrp.Table>  

                <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}>
                <ReactToPrint
                  trigger={() => 
                  <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                  Print
                  </Button>
                  }
                  content={() => this.componentRef}
                  />
              </div>

 
              </Panel>
              </Collapse>
  
          </div>
      )        

      }

   }
}

export default GeneralInterBranchTransactionReportView; 
