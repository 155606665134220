import React,{ useRef } from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import * as serverconfig from '../serverconn'
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';

import {
  Col,
  Row,
} from 'reactstrap';
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var supercompany= ''
var bizuserid= ''

class ReportToPrint extends React.Component {

    state = {
      companyprofile:{},
    };
  
    componentDidMount(){
      if(localStorage.getItem("bizuserid")){
         bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      
      }else{
         bizuserid= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }

      if(localStorage.getItem("supercompany")){
        supercompany= CryptoJS.AES.decrypt(localStorage.getItem("supercompany") , 'my-secret-key@123456').toString(CryptoJS.enc.Utf8)
      }else{
        supercompany= ''
      }


      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      axios.get(`${serverconfig.backendserverurl}/api/supercompanies/${supercompany}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
  
      })

  
    }
  
    render() {
      return (
        <div style={{padding:20}}>
 <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>
                
                  </Col>
       </Row>

             <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>GENERAL LOANS REPORT FROM: {this.props.dateone}  TO: {this.props.datetwo}</h3>

             <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Branch</th>
                          <th>Total Application</th>
                          <th>Total Issuance</th>
                          <th>Total Interest Pay</th>
                          <th>Total Principle Pay</th>
                          <th>General Total Pay</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.props.sheetitems.map(
                        (item)=>(
                          <tr>
                          <td>{item.number}</td>
                          <td>{item.branch}</td>
                          <td>{<CurrencyFormat value={item.totalapplications} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.totalissuance} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.totalinterestpay} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.totalprinciplepay} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.totalgeneralpay} displayType={'text'} thousandSeparator={true}/>}</td>

                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td></td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.generaltotalapplications} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.generaltotalissuance} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.generaltotalinterestpay} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.generaltotalprinciplepay} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.generaltotalgeneralpay} displayType={'text'} thousandSeparator={true}/>}</td>

                        </tr>
                    </tbody>
                </reactstrp.Table>

             
        </div>
      );
    }
  }


class GeneralloansReportView extends React.Component {

  state = {
    sheetitems:[],
    dateone:'',
    datetwo:'',
    generaltotalapplications:0,
    generaltotalassessment:0,
    generaltotalapproval:0,
    generaltotalissuance:0,
    generaltotalinterestpay:0,
    generaltotalprinciplepay:0,
    generaltotalgeneralpay:0,
    datarequested:false,
   };



   componentDidMount(){
    if(localStorage.getItem("sacco")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   
   }else{
      bizuserid= ''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

   if(localStorage.getItem("supercompany")){
    supercompany= CryptoJS.AES.decrypt(localStorage.getItem("supercompany") , 'my-secret-key@123456').toString(CryptoJS.enc.Utf8)
  }else{
    supercompany= ''
  }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

  }
 
   handledatechange= (date, dateString) =>{
     this.setState({ dateone: dateString[0]});
     this.setState({ datetwo: dateString[1]});
   } 


 
   //search sales method
   searchSales=()=>{

    let form_data = new FormData();
    form_data.append('dateone', this.state.dateone);
    form_data.append('datetwo', this.state.datetwo);
    form_data.append('supercompany', supercompany);
    
    if(this.state.dateone===''||this.state.datetwo===''){
      alert("Please Dates are missing")
    }else{
        this.setState({datarequested:true})

        //Now submit sale data to database
        axios.post(serverconfig.backendserverurl+'/customqueries/getgeneralloansreport', form_data,{
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
        .then(res =>{
            this.setState({datarequested:false})
            this.setState({sheetitems:JSON.parse(res.data.report)})
            this.setState({generaltotalapplications:res.data.generaltotalapplications})
            this.setState({generaltotalissuance:res.data.generaltotalissuance})
            this.setState({generaltotalinterestpay:res.data.generaltotalinterestpay})
            this.setState({generaltotalprinciplepay:res.data.generaltotalprinciplepay})
            this.setState({generaltotalgeneralpay:res.data.generaltotalgeneralpay})
        } 
        )
        .catch(error => console.log(error))   
    }

    }

   ////////////////////////////////////
  // USER SEARCH SELECT METHODS
  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }
 

   ////////////////////////////////////////////
   // RENDERING METHOD HERE
   render() {
       
   
       if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
          <Spin indicator={antIcon} />
          </div>
        )
  
      }else{

        return(
          <div>
            <div style={{display: "none"}}>
               &nbsp;&nbsp;
              <ReportToPrint
              generaltotalassessment={this.state.generaltotalassessment} 
              generaltotalapplications={this.state.generaltotalapplications} 
              generaltotalapproval={this.state.generaltotalapproval} 
              generaltotalissuance={this.state.generaltotalissuance} 
              generaltotalinterestpay={this.state.generaltotalinterestpay} 
              generaltotalprinciplepay={this.state.generaltotalprinciplepay} 
              generaltotalgeneralpay={this.state.generaltotalgeneralpay} 
              dateone={this.state.dateone}
              datetwo={this.state.datetwo}
              sheetitems={this.state.sheetitems}
              ref={el => (this.componentRef = el)} /> 
            </div>


            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
              <Panel header="GENERAL LOANS REPORT" key="1">
              <Form  layout="inline" >

                <FormItem label="Date Range">
                    <RangePicker defaultValue={moment()} onChange={this.handledatechange} format={dateFormat} />
                </FormItem>

                <FormItem>
                <Button onClick={this.searchSales} type="primary" htmlType="button">Search</Button>
                </FormItem> 
  
             </Form>
  
             <br></br>
             <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Branch</th>
                          <th>Total Application</th>
                          <th>Total Issuance</th>
                          <th>Total Interest Pay</th>
                          <th>Total Principle Pay</th>
                          <th>General Total Pay</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.sheetitems.map(
                        (item)=>(
                          <tr>
                          <td>{item.number}</td>
                          <td>{item.branch}</td>
                          <td>{<CurrencyFormat value={item.totalapplications} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.totalissuance} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.totalinterestpay} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.totalprinciplepay} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.totalgeneralpay} displayType={'text'} thousandSeparator={true}/>}</td>

                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td></td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.generaltotalapplications} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.generaltotalissuance} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.generaltotalinterestpay} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.generaltotalprinciplepay} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.generaltotalgeneralpay} displayType={'text'} thousandSeparator={true}/>}</td>

                        </tr>
                    </tbody>
                </reactstrp.Table> 

                <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}>
                <ReactToPrint
                  trigger={() => 
                  <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                  Print
                  </Button>
                  }
                  content={() => this.componentRef}
                  />
              </div>

              </Panel>
              </Collapse>
  
          </div>
      )        

      }

   }
}

export default GeneralloansReportView; 
