import React from 'react';
import MyContext from './MyContext';

class MyProvider extends React.Component {
       state = {
        showbranchdashboard: false
       }
      render() {
        return (
            <MyContext.Provider value={
            {   state: this.state,
                setshowbranchdashboard: (value) =>{
                //set status
                this.setState({showbranchdashboard: value })
                if(value===true){
                    window.location.reload(false)
                    window.location.replace("/");
                }
                }}}>
            {this.props.children}  
            </MyContext.Provider>)
    }
}

export default MyProvider;