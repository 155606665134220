import React from 'react';
import { Route } from 'react-router-dom';
import HomeIndex from './views/containers/supercompanyindex'
import Index from './views/containers/index'
import IDTypeList from './views/containers/IDListView'
import IDTypeDetail from './views/containers/IDDetailView'
import ExpenseCategoryList from './views/containers/ExpenseCategorysListView'
import ExpenseCategoryDetail from './views/containers/ExpenseCategoryDetailView'
import StaffRoleList from './views/containers/StaffRolesListView'
import StaffRoleDetail from './views/containers/StaffRoleDetailView'
import ExpenseList from './views/containers/ExpenseListView'
import ExpenseDetail from './views/containers/ExpenseDetailView'
import AssetList from './views/containers/AssetListView'
import AssetDetail from './views/containers/AssetDetailView'
import AssetCategoryList from './views/containers/AssetCategorysListView'
import AssetCategoryDetail from './views/containers/AssetCategoryDetailView'
import StaffList from './views/containers/StaffListView'
import StaffDetail from './views/containers/StaffDetailView'
import AccountTypeList from './views/containers/AccountTypeListView'
import AccountTypeDetail from './views/containers/AccountTypeDetailView'
import LoanTypeList from './views/containers/LoanTypeListView'
import LoanTypeDetail from './views/containers/LoanTypeDetailView'
import NonWorkingDayList from './views/containers/NonWorkingDayListView'
import NonWorkingDayDetail from './views/containers/NonWorkingDayDetailView'
import IncomeCategoryList from './views/containers/IncomeCategorysListView'
import IncomeCategoryDetail from './views/containers/IncomeCategoryDetailView'
import CashAtBankList from './views/containers/CashAtBankListView'
import CashAtBankDetail from './views/containers/CashAtBankDetailView'
import ExternalLoanList from './views/containers/ExternalLoanListView'
import ExternalLoanDetail from './views/containers/ExternalLoanDetailView'
import MemberList from './views/containers/MemberListView'
import MemberDetail from './views/containers/MemberDetailView'
import AccountDepositView from './views/containers/AccountDepositView'
import AccountWithdrawView from './views/containers/AccountWithdrawView'
import LoanApplicationForm from './views/components/LoanApplicationForm'
import  LoanCalculator from './views/components/LoanCalculator'
import  LoanApplicationsList from './views/containers/LoanApplicationListView'
import  LoanApplicationDetail from './views/containers/LoanApplicationDetailView'
import  LoanAssessmentForm from './views/components/LoanAssessmentForm'
import  LoanAssessmentList from './views/containers/LoanAssessmentListView'
import  LoanAppovalForm from './views/components/LoanApprovalForm'
import  LoanApprovalList from './views/containers/LoanApprovalListView'
import  LoanIssuanceForm from './views/components/LoanIssuanceForm'
import  LoanIssuanceList from './views/containers/LoanIssuancesListView'
import  LoanIssueDetail from './views/containers/LoanIssueDetails'
import  LoanRepaymentView from './views/containers/LoanRepaymentView'
import  IncomeList from './views/containers/IncomeListView'
import IncomeDetail from './views/containers/IncomeDetailView'
import LoanGuarantorsList from './views/containers/LoanGuarantorsListView'
import LoanConsentsList from './views/containers/LoanConsentsListView'
import OpeningItemList from './views/containers/AccountOpeningItemsListView'
import TillSheetReport from './views/containers/TillSheetReportView'
import TransactionReport from './views/containers/TransactionReportView'
import MemberSavingsReport from './views/containers/MemberSavingsReportView'
import IncomeStatementReport from './views/containers/IncomeStatementView'
import DayLoanReport from './views/containers/DayLoanReportView'
import OutStandingLoanReport from './views/containers/OutStandingLoanReportView'
import LoanRepaymentStatusReport from './views/containers/LoanRepaymentStatusReportView'
import LoanDefaultsReport from './views/containers/LoanDefaultsReportView'
import LoanAgingReport from './views/containers/LoanAgingReportView'
import LoanDisbursmentReport from './views/containers/LoanDisbursmentReportView'
import LoanRepaymentsReport from './views/containers/LoanRepaymentsReportView'
import BalanceSheetReport from './views/containers/BalanceSheetView'
import ShareTransferView from './views/containers/ShareTransferView'
import SubAccountTransferView from './views/containers/SubAccountTransferView'
import FinancialStatementReport from './views/containers/FinancialStatementReportView'
import SaccoDetail from './views/containers/saccoDetailView'
import AccountList from './views/containers/AccountsListView'
import AccountDetail from './views/containers/AccountDetailView'
import SendSMSMessageView from './views/containers/SendSMSMessageView'
import GroupList from './views/containers/GroupsListView'
import GroupDetail from './views/containers/GroupDetailView'
import UserBalancesReport from './views/containers/UserBalancesReportView'
import MyContext from './views/containers/MyContext';

import InterBranchTransactionReportView from './views/containers/InterBranchTransactionReportView'
import  InterBranchMoneyTransfersReportView from './views/containers/InterBranchMoneyTransfersReportView'

import TransactionGeneralReport from './views/containers/TransactionGeneralReportView'
import GeneralIncomeStatementReport from './views/containers/GeneralIncomestatementReportView'
import GeneralloansReportView from './views/containers/GeneralloansReportView'
import GeneralAccountsReportView from './views/containers/GeneralAccountsReportView'

import GeneralInterBranchTransactionReportView from './views/containers/GeneralInterBranchTransactionReportView'
import GeneralInterBranchMoneyTransfersReportView from './views/containers/GeneralInterBranchMoneyTransfersReportView'
import TransactionReportPerChannel from './views/containers/TransactionReportViewPerChannel'

import SaccoMembersPerUserReport from './views/containers/SaccoMembersPerUserReportView'
import SafeBankTransfersReport from './views/containers/SafeBankTransfersReportView'
import SafeUserTransferReport from './views/containers/SafeUserTransfersReportView'
import SaccoSmsUsagestatementreport from './views/containers/saccosmsusagestatementreport'

import MemberReserveReport from './views/containers/MemberReserveReportView'
import SalaryRequistionList from './views/containers/salaryrequisitionListView'
import LoanDefaultsReportDaily from './views/containers/LoanDefaultsReportViewDaily'


import AllBranchesIndex from './views/containers/allbranchesindex'

const SaccoBaseRouter = () =>
{
        return (
            <MyContext.Consumer>
            {(context) => (
            <div>
                {
                   localStorage.getItem('dashboardstatus')==='branch' || context.state.showbranchdashboard===true? 
                <div>
                <Route exact path='/' component={Index} />
                <Route exact path='/idtypes/' component={IDTypeList}  />
                <Route exact path='/idtypes/:idtypeID' component={IDTypeDetail}  />
                <Route exact path='/expensecategories/' component={ExpenseCategoryList}  />
                <Route exact path='/expensecategories/:expensecategoryID' component={ExpenseCategoryDetail}  />
                <Route exact path='/staffroles/' component={StaffRoleList}  />
                <Route exact path='/staffroles/:staffroleID' component={StaffRoleDetail}  />
                <Route exact path='/expenses/' component={ExpenseList}  />
                <Route exact path='/expenses/:expenseID' component={ExpenseDetail}  />
                <Route exact path='/assets/' component={AssetList}  />
                <Route exact path='/assets/:assetID' component={AssetDetail}  />
                <Route exact path='/assetcategories/' component={AssetCategoryList}  />
                <Route exact path='/assetcategories/:assetcategoryID' component={AssetCategoryDetail}  />
                <Route exact path='/staffs/' component={StaffList}  />
                <Route exact path='/staffs/:staffID' component={StaffDetail}  />
                <Route exact path='/accounttypes/' component={AccountTypeList}  />
                <Route exact path='/accounttypes/:accountTypeID' component={AccountTypeDetail}  />
                <Route exact path='/loantypes/' component={LoanTypeList}  />
                <Route exact path='/loantypes/:loanTypeID' component={LoanTypeDetail}  />
                <Route exact path='/nonworkingdays/' component={NonWorkingDayList}  />
                <Route exact path='/nonworkingdays/:dayID' component={NonWorkingDayDetail}  />
                <Route exact path='/incomecategories/' component={IncomeCategoryList}  />
                <Route exact path='/incomecategories/:categoryID' component={IncomeCategoryDetail}  />
                <Route exact path='/cashatbank/' component={CashAtBankList}  />
                <Route exact path='/cashatbank/:cashID' component={CashAtBankDetail}  />
                <Route exact path='/externalloans/' component={ExternalLoanList}  />
                <Route exact path='/externalloans/:loanID' component={ExternalLoanDetail}  />
                <Route exact path='/members/' component={MemberList}  />
                <Route exact path='/members/:memberID' component={MemberDetail}  />
                <Route exact path='/deposit/' component={AccountDepositView}  />
                <Route exact path='/withdraw/' component={AccountWithdrawView}  />
                <Route exact path='/loanapplication/' component={LoanApplicationForm}  />
                <Route exact path='/loancalculator/' component={LoanCalculator}  />
                <Route exact path='/loanapplications/' component={LoanApplicationsList}  />
                <Route exact path='/loanapplications/:loanapplicationID' component={LoanApplicationDetail}  />
                <Route exact path='/makeassessment/:loanapplicationID' component={LoanAssessmentForm}  />
                <Route exact path='/loanassessments/' component={LoanAssessmentList}  />
                <Route exact path='/approveloan/:loanapplicationID' component={LoanAppovalForm}  />
                <Route exact path='/loanapprovals/' component={LoanApprovalList}  />
                <Route exact path='/issueloan/:loanapplicationID' component={LoanIssuanceForm}  />
                <Route exact path='/loanissues/' component={LoanIssuanceList}  />
                <Route exact path='/loanissues/:loanissueID' component={LoanIssueDetail}  />
                <Route exact path='/loanrepayment/' component={LoanRepaymentView}  />
                <Route exact path='/incomes/' component={IncomeList}  />
                <Route exact path='/incomes/:incomeID' component={IncomeDetail}  />
                <Route exact path='/loanguarantors/:applicationID' component={LoanGuarantorsList}  />
                <Route exact path='/loanconsents/:applicationID' component={LoanConsentsList}  />
                <Route exact path='/accountopeningitems/:accountTypeID' component={OpeningItemList}  />
                <Route exact path='/tillsheet/' component={TillSheetReport}  />
                <Route exact path='/accounttransactions/' component={TransactionReport} />
                <Route exact path='/memberbalances/' component={MemberSavingsReport} />
                <Route exact path='/incomestatement/' component={IncomeStatementReport} />
                <Route exact path='/dayloanreport/' component={DayLoanReport} />
                <Route exact path='/loanoutstanding/' component={OutStandingLoanReport} />
                <Route exact path='/loanrepaymentstatus/' component={LoanRepaymentStatusReport} />
                <Route exact path='/loandefaults/' component={LoanDefaultsReport} />
                <Route exact path='/loanaging/' component={LoanAgingReport} />
                <Route exact path='/loandisbursement/' component={LoanDisbursmentReport} />
                <Route exact path='/loanrepaymentreport/' component={LoanRepaymentsReport} />
                <Route exact path='/balancesheet/' component={BalanceSheetReport} />
                <Route exact path='/sharetransfer/' component={ShareTransferView} />
                <Route exact path='/subaccounttransfer/' component={SubAccountTransferView} />
                <Route exact path='/financialstatement/' component={FinancialStatementReport} />
                <Route exact path='/companyprofile/' component={SaccoDetail} />
                <Route exact path='/useraccounts/' component={AccountList} />
                <Route exact path='/useraccounts/:accountID' component={AccountDetail} />
                <Route exact path='/messaging/' component={SendSMSMessageView} />
                <Route exact path='/groups/' component={GroupList} />
                <Route exact path='/groups/:groupID' component={GroupDetail}  />
                <Route exact path='/userbalances/' component={UserBalancesReport} />
                <Route exact path='/interbranchtransactionsreport/' component={InterBranchTransactionReportView} />
                <Route exact path='/interbranchmoneytransferreport/' component={InterBranchMoneyTransfersReportView} />
                <Route exact path='/accounttransactionschannel/' component={TransactionReportPerChannel} />
                <Route exact path='/memberperuserreport/' component={SaccoMembersPerUserReport} />
                <Route exact path='/safebanktransfersreport/' component={SafeBankTransfersReport} />
                <Route exact path='/safeusertransfersreport/' component={SafeUserTransferReport} />
                <Route exact path='/saccosmsusagestatement/' component={SaccoSmsUsagestatementreport} />
                <Route exact path='/loanreservereport/' component={MemberReserveReport} />
                <Route exact path='/salaryrequisition/' component={SalaryRequistionList} />
                <Route exact path='/loandailydefaultsreport/' component={LoanDefaultsReportDaily}  />

                </div>
                :
                   <div>
                   <Route exact path='/' component={HomeIndex} />
                   <Route exact path='/generaltransactionsreport/' component={TransactionGeneralReport} />
                   <Route exact path='/generalincomestatementreport/' component={GeneralIncomeStatementReport} />
                   <Route exact path='/generalloansreport/' component={GeneralloansReportView} />
                   <Route exact path='/generalaccountsreport/' component={GeneralAccountsReportView} />
                   <Route exact path='/generalinterbranchtransactionsreport/' component={GeneralInterBranchTransactionReportView} />
                   <Route exact path='/generalinterbranchmoneytransfersreport/' component={GeneralInterBranchMoneyTransfersReportView} />
                   <Route exact path='/branches/' component={AllBranchesIndex} />

                   </div>
                }

            </div>

            )}
            </MyContext.Consumer>
        );

}
export default SaccoBaseRouter;


