import React,{ useRef } from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Tooltip,Popover,Result,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const toWords = new ToWords();

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});


var token= ''
var sacco= ''
var username=''

class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

 

  }

  render() {
    return (
      <div style={{padding:20}}>
            
          <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
          <h3 style={{color:'black'}}>{this.state.companyprofile.sacco_name}-{this.state.companyprofile.branchname}</h3>
          <h6 style={{color:'black'}}>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
          {
          this.state.companyprofile.RegisteredPhoneNumber2==""?
          "":","+this.state.companyprofile.RegisteredPhoneNumber2
          }
          {
          this.state.companyprofile.RegisteredPhoneNumber3==""?
          "":","+this.state.companyprofile.RegisteredPhoneNumber3
          }
          </h6>               
          <h5 style={{color:'black'}}>Email: {this.state.companyprofile.company_email}</h5>
          <h5 style={{color:'black'}}>Wesite: {this.state.companyprofile.website}</h5>
          <h5 style={{color:'black'}}>{this.state.companyprofile.box_number}</h5>
           <h4 style={{color:'black'}}>SLIP NO: {this.props.slipnumber}</h4>
           <h4 style={{color:'black'}}>DATE: {this.props.date}</h4>
           <h5 style={{color:'black'}}>Country: {this.state.companyprofile.country} || Currency: {this.state.companyprofile.currency}</h5>
           <h3 style={{strong:true,color:'black',fontWeight:'bolder'}}>DEPOSIT RECEIPT</h3>

           <table cellpadding="2" cellSpacing="2" border="1">
            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>ACC NUMBER</td>
              <td style={{fontWeight:'bold'}}>{this.props.account.AccountNumber}</td>
            </tr>

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>ACC NAME</td>
              <td style={{fontWeight:'bold'}}>{this.props.account.FullName}</td>
            </tr>  

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>DEPOSITED AMOUNT</td>
              <td style={{fontWeight:'bold'}}><CurrencyFormat  value={this.props.paidamount} displayType={'text'} thousandSeparator={true} /></td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>In Words</td>
              <td style={{fontWeight:'bold'}}>{ toWords.convert(this.props.paidamount)}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>PAID IN BY</td>
              <td style={{fontWeight:'bold'}}>{this.props.paidinby}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>SLIP NUMBER</td>
              <td style={{fontWeight:'bold'}}>{this.props.slipnumber}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>CONTACT</td>
              <td style={{fontWeight:'bold'}}>{this.props.transactorcontact}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>TELLER</td>
              <td style={{fontWeight:'bold'}}>{username}</td>
            </tr> 

           </table>


        
    </div>
    );
  }
}



class LoanRepaymentView extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    selectedaccount:'',
    selectedaccountobject:{},
    members:[],
    datarequested:true,
    paidamount:0,
    slipnumber:'',
    paidinby:'',
    transactorcontact:'',
    availableloans:[],
    datasubmittedsuccessfully:false,
    lastslipnumber:0,
    selectedloan:'',
    loanschedules:[],
    selectedschedule:'',
    selectedscheduleobject:{},
    paymentmode:'',
    paidamount:0,
    fine:0,
    debitaccount:'',
    disabledamount:false
  }

  callback(key) {
    console.log(key);
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
   
   }else{
      sacco= ''
      username=''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          members:res.data
        })

        this.setState({datarequested:false})

    })

  }

  

  
  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
   onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }

  handleselectedaccountChange= (e) =>{
    this.setState({ selectedaccount: e});
    //make query for last transactions
    axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
      .then(res => {  
          this.setState({selectedaccountobject: res.data})
      })

      axios.get(`${serverconfig.backendserverurl}/api/loanissues/?account=${e}`)
      .then(res => {  
          this.setState({availableloans: res.data})
          console.log(res.data)
      })

      this.setState({slipnumber:Number(this.state.lastslipnumber)+1})

  }


  //handle client change
  handleselectedloanChange= (e) =>{
    this.setState({selectedloan:e});

    axios.get(`${serverconfig.backendserverurl}/api/loanschedules/?loanissue=${e}`)
    .then(res => {  
        this.setState({loanschedules: res.data})
    })
  }

  //handle selected schedule change
  handleselectedscheduleChange=(e)=>{
    this.setState({selectedschedule:e})
      axios.get(`${serverconfig.backendserverurl}/api/loanschedules/${e}`)
      .then(res => {  
          this.setState({selectedscheduleobject: res.data})
      })
    }

    ///handle debit account change
    handledebitaccountChange=(e)=>{
      this.setState({debitaccount:e})
    }

  

  //handle payment mode change
  handlepaymentmodeChange=(e)=>{
    this.setState({paymentmode:e})

    if(e==="principleonly"){
      this.setState({paidamount:this.state.selectedscheduleobject.principle_balance})
      this.setState({disabledamount:false})
    }else if(e==="interestonly"){
      this.setState({paidamount:this.state.selectedscheduleobject.interest_balance})
      this.setState({disabledamount:false})
    }else if (e==="interestprinciple"){
      this.setState({paidamount:Number(this.state.selectedscheduleobject.interest_balance)+Number(this.state.selectedscheduleobject.principle_balance)})
      this.setState({disabledamount:true})
    }

  }

  handlepaidamountChange= (e) => this.setState({ paidamount: e.target.value});
  handlefineChange= (e) => this.setState({ fine: e.target.value});


  //submit the deposit now
  handleFormSubmit=(event) =>{

      this.setState({datarequested:true})
      console.log(this.state.selectedschedule)
  
      let form_data = new FormData();
      form_data.append('paymentmonthid',this.state.selectedschedule);
      form_data.append('paymentmode', this.state.paymentmode);
      form_data.append('enteredamount', this.state.paidamount);
      form_data.append('debitaccount', this.state.debitaccount);
      form_data.append('fine', this.state.fine);
      form_data.append('sacco', sacco);
      form_data.append('username', username);
      form_data.append('loanid', this.state.selectedloan);

      axios.post(serverconfig.backendserverurl+'/customqueries/clear_loan_month', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({datarequested:false})
        message.info(res.data.message)
        if (res.data.message==="Success"){
          this.setState({datasubmittedsuccessfully:true})
        }
        
      })
      .catch(error => console.log(error))
    
  }



  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
            <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <Result
                    status="success"
                    title="Successfully Made Loan Repayment "
                    subTitle="Please finish off transaction"
                    extra={[
                      ,
                      <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                    ]}
                    />

                    </Card>

                    </Col>

            </Row>


          </div>

        )

      }else{
        return(
          <div>
            <div>
  
              <Row >
                  <Col xs="12" sm="6" lg="12">
                    <Card>
                    <h3 style={{fontWeight:'bolder',color:'#154360'}}>Member Loan Repayment</h3>

                    <FormItem label=" Select Member Account">
                        <Select 
                        placeholder="Account No | Name" 
                        style={{  }} 
                        value={this.state.selectedaccount}
                        onChange={this.handleselectedaccountChange} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}
                                             
                        >
                          {this.state.members.map(
                            (accnt)=>(
                              <Option value={accnt.id}>{accnt.AccountNumber} | {accnt.FullName}</Option>
                            ))}
                        </Select>
                    </FormItem>

                    
                    <FormItem label="Select Loan to Repay">
                        <Select 
                        placeholder="Select Loan to Repay" 
                        style={{  }} 
                        value={this.state.selectedloan}
                        onChange={this.handleselectedloanChange} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}
                                              
                        >
                          {this.state.availableloans.map(
                            (loan)=>(
                              <Option value={loan.id}>Amount {loan.approved_amount} | Date {loan.date} | Months {loan.loan_period}</Option>
                            ))}
                        </Select>
                      </FormItem>
                      <br></br>


                    <h3 style={{fontWeight:'bold',color:'#154360'}}>Account Details</h3>
                    <h4>Account Number:{this.state.selectedaccountobject.AccountNumber}</h4>
                    <h4>Account Contact:{this.state.selectedaccountobject.RegisteredPhoneNumber1}</h4>
                    <h4>Account Name:{this.state.selectedaccountobject.FullName}</h4>
                    <h4>Account Balance:<CurrencyFormat value={this.state.selectedaccountobject.UserBalance} displayType={'text'} thousandSeparator={true}  /> UGX</h4>
                    <h4>Status:{this.state.selectedaccountobject.account_status}</h4>
  
                    <br></br>

                    <h3 style={{fontWeight:'bold',alignSelf:'center',color:'#154360'}}>LOAN REPAYMENT FORM</h3>
                    <Form
                      onFinish={(event) => this.handleFormSubmit(event)}
                    >
                    <FormItem label={<h4 style={{fontWeight:'bold'}}>Select Loan Schedule:</h4>}>
                        <Select 
                        placeholder="Select Loan Schedule" 
                        style={{  }} 
                        value={this.state.selectedschedule}
                        onChange={this.handleselectedscheduleChange} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}
                                              
                        >
                          {this.state.loanschedules.map(
                            (schedule)=>(
                              <Option value={schedule.id}>Date: {schedule.date} | Principle Balance: {schedule.principle_balance} | Interest Balance: {schedule.interest_balance}</Option>
                            ))}
                        </Select>
                      </FormItem>


                      <FormItem label={<h4 style={{fontWeight:'bold'}}>Payment Mode:</h4>}>
                        <Select 
                        placeholder="Select Mode" 
                        style={{  }} 
                        value={this.state.paymentmode}
                        onChange={this.handlepaymentmodeChange} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}
                                              
                        >
                          <option value=""></option>
                          <option value="interestonly">Interest Only</option>
                          <option value="principleonly">Principle Only</option>
                          <option value="interestprinciple">Interest & Principle</option>
                        </Select>
                      </FormItem>

  
                    <FormItem label={<h4 style={{fontWeight:'bold'}}>Principle /Interest Amount:</h4>}>
                      <Input disabled={this.state.disabledamount} type='number' placeholder="Amount" value={this.state.paidamount} onChange={this.handlepaidamountChange}/>
     
                    </FormItem>
  
                    <FormItem label={<h4 style={{fontWeight:'bold'}}>Fine:</h4>}>
                      <Input type='number' placeholder="Amount" value={this.state.fine} onChange={this.handlefineChange}/>
                    </FormItem>

                    <FormItem label={<h4 style={{fontWeight:'bold'}}>Debit Account?:</h4>}>
                        <Select 
                        placeholder="Debit Account?" 
                        style={{  }} 
                        value={this.state.debitaccount}
                        onChange={this.handledebitaccountChange} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}                     
                        >
                          <option value=""></option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </Select>
                      </FormItem>
                
  
                    <FormItem>
                      <Button  type="primary" htmlType="submit">Submit</Button>
                    </FormItem>
                  </Form>
  
  
                    </Card>
                   
                  </Col>
  
         
            </Row>
  
            </div>
  
  
       </div>              
                      
        )
      }

    }
   
  }
}

export default LoanRepaymentView; 
