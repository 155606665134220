import React,{useState,useEffect,lazy} from 'react';
import axios from 'axios'
import {  Slider,Statistic,Tabs,Modal } from 'antd';
import { ArrowUpOutlined,BankFilled,MinusCircleFilled,ToolOutlined, ArrowDownOutlined,FileFilled,WalletFilled,SearchOutlined,FundViewOutlined,
   UserOutlined, AccountBookOutlined,CalculatorFilled,SwapOutlined,GroupOutlined,AccountBookFilled, DollarCircleOutlined,EditFilled,MessageFilled,DollarOutlined,CarFilled,LoadingOutlined,PlusCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import { Line,Pie,Bar } from 'react-chartjs-2';
import {withGetScreen} from 'react-getscreen'
import { Tooltip,Popover,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import {
  Col,
  Row,
} from 'reactstrap';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'
import * as serverconfig from '../serverconn'
import * as serversocketconfig from '../serversocketconfig'
import { MdShoppingCart } from 'react-icons/md';

import CurrencyFormat from 'react-currency-format';
import Highlighter from 'react-highlight-words';
import Websocket from 'react-websocket';
import { Link } from 'react-router-dom';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { NavLink } from 'react-router-dom'
import  MemberForm from '../components/MemberForm'
import  LoanCalculator from '../components/LoanCalculator'
import { connect } from 'react-redux'
import { withRouter} from 'react-router-dom';
import * as actions from '../../store/actions/auth'
import MyContext from './MyContext';


var CryptoJS = require("crypto-js");

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


const style = { background: '#0092ff', padding: '8px 0',borderRadius: '7px',justifyContent:'center', alignItems:'center',display: 'flex', };
const dateFormat = 'DD/MM/YYYY';
const yeardateFormat = 'YYYY';
const monthdateFormat = 'MM/YYYY';

const { Meta } = Card;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const FormItem=Form.Item;
const { Option } = Select;

var bizuserid= ''
var supercompany= ''
var token= ''


class AllBranchesIndex extends React.Component {

  state = {
    currentmonth:moment().format(monthdateFormat).toString(),
    companyprofile:{},
    datarequested:true,
    totalmemberaccounts:0,
    totalfemaleaccounts:0,
    totalmaleaccounts:0,
    totalloansthismonth:0,
    totalmembersavings:0,
    memberformvisible:false,
    transactformvisible:false,
    calculatorformvisible:false,
    saccos:[],
    loansformvisible:false,
    monthlyloanissuances:0,
    monthlyloanapplications:0,
    monthlyloanassessments:0,
    monthlyloanapprovals:0,
    dashboardlocked:false,
    utilitiesmodalvisible:false,
    userprofile:{},
    userrights:{},
    nonworkingday:{},
    branchdashboardvisible:false,
    branchaccounts:[],
    branchaccountnames:[],

    monthlywithdrawamounts : [],
    withdrawmonths:[],
    monthlydepositamounts : [],
    depositmonths:[],
    monthlyexpenseamounts:[],
    expensemonths:[],
    monthlyincomeamounts:[],
    incomemonths:[],
    monthlyissueamounts:[],
    issuemonths:[],

    monthlyprincipleamounts:[],
    principlemonths:[],

    monthlyinterestamounts:[],
    interestmonths:[],

  };



  callback =(key) =>{
    console.log(key);
    console.log(this.state.yearlymonthlysales)
  }

  componentDidMount(){

    if(localStorage.getItem("supercompany")){
      supercompany= CryptoJS.AES.decrypt(localStorage.getItem("supercompany") , 'my-secret-key@123456').toString(CryptoJS.enc.Utf8)
    }else{
      supercompany= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }
    
    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }



   let form_data = new FormData();
   form_data.append('userid', bizuserid);
   
   //get the user profile here
   axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data,{
     headers: {
       'content-type': 'multipart/form-data'
     }
     })
   .then(res => {
       this.setState({
         userprofile:res.data
       })
   })


    axios.get(serverconfig.backendserverurl+`/api/supercompanies/${supercompany}`)
    .then(res => {
        this.setState({
          companyprofile:res.data
        })
    })


    axios.get(serverconfig.backendserverurl+`/api/saccos/?supercompany=${supercompany}`)
    .then(res => {
        this.setState({
          saccos:res.data
        })
        console.log(res.data)

        this.setState({datarequested:false})
    })



  }


//render branch based dashboar
displayBranchDashboard=(saccoid)=>{
    localStorage.setItem('sacco',CryptoJS.AES.encrypt(String(saccoid), 'my-secret-key@12345').toString());
    localStorage.setItem('dashboardstatus','branch');
  }


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ?(
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};


  render() {
    

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
  
    }else{

        return(
          <div className="animated fadeIn">  
          <h3 style={{fontStyle:'oblique',fontWeight:'bolder'}}>{this.state.companyprofile.name} - BRANCHES </h3>
          <Row>

          {
            this.state.saccos?
            this.state.saccos.map(
              (accnt)=>(
                <MyContext.Consumer>
                {(context) => (
                 <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
                  <Tooltip title={accnt.sacco_name+" | "+accnt.branchname+" click to browse branch"} placement="top">
                  <Card
                        onClick={()=>{this.displayBranchDashboard(accnt.id)
                          context.setshowbranchdashboard(true)
                          
                        }}
                        hoverable
                        style={{ 
                          borderRadius:20,
                          background:"white",
                          display: 'flex',
                          justifyContent:'center',
                          padding:4,
                          alignItems:'center' }}
                      >
          
                          <div style={{width:'100%',
                            padding:10,
                            alignItems:'center',
                            alignSelf:'center',
                            display:'flex',
                            justifyContent:'center',
                            flexDirection:'column'
                            }}>
                        <BankFilled style={{color:'#2F54EB',fontSize: '45px'}}/>

                        <h6 style={{fontWeight:'bold',alignSelf:'center'}}>{accnt.sacco_name}</h6>

                        </div>
                      </Card>

                    </Tooltip>            
                  
                  </Col>  
               )}
                </MyContext.Consumer>
                ))
            :
            null
          }
            </Row>

          </div>
        )

    }
  
  }
}


export default AllBranchesIndex; 
